import React from 'react';

import { AngleRightRegularIcon } from '../../../../shared/icons/icons';
import Link from '../../../link/link';
import Typography from '../../../typography/typography';

import { isArrayWithLength } from '../../../../shared/utils/validators/validators';

import './breadcrumbs.scss';

interface BreadcrumbsProps {
  className?: string;
  crumbs: {
    name: string;
    url?: string;
  }[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { className, crumbs } = props;

  return isArrayWithLength(crumbs) ? (
    <div className={`${className || ''} breadcrumbs`} styleName="container">
      <ol className="breadcrumbs__list" styleName="crumbs">
        {crumbs.map((crumb, idx) => (
          <li
            className="breadcrumbs__list-item"
            key={`bc-${idx}`}
            styleName="crumb"
          >
            {crumb.url ? (
              <Link
                className="dt-link breadcrumbs__list-item-link"
                styleName="crumb-link"
                to={crumb.url}
              >
                <Typography size="extraSmall">{crumb.name}</Typography>
                <AngleRightRegularIcon
                  className="breadcrumbs__crumb-arrow"
                  styleName="crumb-arrow"
                />
              </Link>
            ) : (
              <div styleName="crumb-text">
                <Typography size="extraSmall">{crumb.name}</Typography>
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  ) : null;
}

export default Breadcrumbs;
