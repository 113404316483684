import React, { PropsWithChildren } from 'react';

import Breadcrumbs from './components/breadcrumbs/breadcrumbs';
import Content from './components/content/content';
import PageTitle from './components/page-title/page-title';
import Section from './components/section/section';

interface PageProps {
  className?: string;
  pageRef?: React.RefObject<HTMLDivElement>;
}

function Page(props: PropsWithChildren<PageProps>) {
  const { children, className, pageRef } = props;

  return (
    <div className={`${className || ''} page`} ref={pageRef}>
      {children}
    </div>
  );
}

export { PageTitle, Breadcrumbs, Content, Section };
export default Page;
